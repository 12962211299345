import { AxiosConnect } from "./axios.connect";

export class RewardConnect {

    async createReward(data) {
     const { data: response } = await AxiosConnect.post(`/reward`, data);
     if(response && response.data){
        return response;
     } else {
        alert("Network Error, could not create Reward,");
     }
    }

    async allRewards() {
        const { data: response } = await AxiosConnect.get("/reward");
        if(response && response.data){
           return response;
        } else {
           alert("Network Error, could not create Reward,");
        }
    }

    async editReward(data) {
        const { data: response } = await AxiosConnect.post(`/reward/update`, data);
        if(response && response.data){
           return response;
        } else {
           alert("Network Error, could not create Reward,");
        }
    }

    handleTheError(AxiosError) {
        let errorMessage = AxiosError.response?.data?.message || AxiosError.message || 'Request failed';
        console.error('Error message:', errorMessage);
        return errorMessage;
    }
}