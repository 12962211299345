import { AxiosConnect } from "./axios.connect";

export class AdminConnect {
  async Login(data) {
    const { data: response } = await AxiosConnect.post(`/admins/login`, data);
    if (response && response.data) {
      return response;
    } else {
      alert("Network Error, could not create airdrop..");
    }
  }

  async getUsers({ sortBy, isPremium, minCoin, maxCoin }) {
    const queryParams = new URLSearchParams();
    if (sortBy) queryParams.append("sortBy", sortBy);
    if (isPremium !== undefined) queryParams.append("isPremium", isPremium);
    if (minCoin !== undefined) queryParams.append("minCoin", minCoin);
    if (maxCoin !== undefined) queryParams.append("maxCoin", maxCoin);
    const queries = `?${queryParams.toString()}`;
    console.log({ queries });
    const { data: response } = await AxiosConnect.get(
      `/admins/users/all${queries}`
    );
    if (response.data) {
      return response.data;
    } else {
      return null;
    }
  }

  async createAdmin(data) {
    const { data: response } = await AxiosConnect.post(
      `/admins/create_admin`,
      data
    );
    if (response.data) {
      return response;
    } else {
      return null;
    }
  }

  async getReferrals(telegramId) {
    const { data: response } = await AxiosConnect.get(`/admins/users/referrals/${telegramId}`);
    if (response && response.data) {
      return response;
    } else {
      alert("Network Error, user friends not found.");
    }
  }

  async getUserBy(identifier) {
    const { data: response } = await AxiosConnect.post(`/admins/users/by/`, { identifier });
    if (response && response.data) {
      return response;
    } else {
      alert("Unable to fetch users.");
    }
  }

  async getCount() {
    const { data: response } = await AxiosConnect.get(`/admins/users/count/`);
    if (response && response.data) {
      return response;
    } else {
      alert("Unable to fetch users.");
    }
  }

  handleTheError(AxiosError) {
    let errorMessage =
      AxiosError.response?.data?.message ||
      AxiosError.message ||
      "Request failed";
    console.error("Error message:", errorMessage);
    return errorMessage;
  }

}
