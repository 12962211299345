import React from 'react';

function ProjectList({ projects, onAddLevel, onEdit }) {
   return (
      <div className="max-w-sm mx-auto mt-10">
         { projects && projects.map((project) => (
            <div key={project._id} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 p-4 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
               <div className="flex items-center mb-2">
                  <img src={project.image} alt={project.name} className="w-16 h-16 rounded-full mr-4" />
                  <div>
                     <p className="text-lg font-semibold">{project.name}</p>
                     <p className="text-sm text-gray-500 dark:text-gray-400">{project.symbol}</p>
                  </div>
               </div>
               <p className="mb-2">{project.description}</p>
               <p className="mb-2">Username: {project.telegramChannelUsername}</p>
               <p className="mb-2">ID: {project.telegramChannelId}</p>

               <div className="flex justify-between mb-2">
                  <a href={project.twitterLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 dark:text-blue-400">Twitter</a>
                  <a href={project.telegramLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 dark:text-blue-400">Telegram</a>
                  <a href={project.instagramLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 dark:text-blue-400">Instagram</a>
                  <a href={project.discordLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 dark:text-blue-400">Discord</a>
               </div>
               <div className="flex justify-between">
                  <button
                     onClick={() => onAddLevel(project.project_id)}
                     className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                     Add Level
                  </button>

                  <a
                     href={`/levels/${project.project_id}`}
                     className="text-white cursor-pointer bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                     View Level
                  </a>

                  <button
                     onClick={() => onEdit(project)}
                     className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                  >
                     Edit
                  </button>
               </div>
            </div>
         ))}
      </div>
   );
}

export default ProjectList;
