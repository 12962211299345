import React, { useState } from "react";
import { AdminConnect } from "../../connect/admin.connect";

function Register() {
  const admin = new AdminConnect();
  const [email, setEmail] = useState("");
  const [ btn, setBtn ] = useState(false);
  const [ btnTxt, setBtnTxt ] = useState("Create account")
  const [ authCode, setAuthCode ] = useState(""); 


  const RegisterEmail = async (e) => {
        e.preventDefault();
        try {
            setBtnTxt("Creating Account");
            setBtn(true);
         const { data: response } = await admin.createAdmin({ email });
         console.log({ response });
         if(response) {
          const authenticatorSecret = response?.authenticatorSecret;
          setAuthCode(authenticatorSecret.secret);
            setBtn(false);
            setBtnTxt("Create Account");
         }
        } catch (error) {
        const errorMessage = admin.handleTheError(error);
        alert(errorMessage);
        }
  }


  return (
    <div>
      <div className="max-w-sm mx-auto mt-40">
        <p className="text-white font-bold text-center text-[40px] mb-2">
          Register
        </p>
        <div className="mb-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Email
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>


        <button
              type="button"
              disabled={btn}
              onClick={(e) => RegisterEmail(e)}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {btnTxt}
        </button>
        { authCode.length > 2 && <p className="text-white">
            <span>Please add this secret to your authenticator app : {authCode} </span>
          </p>}
      </div>
    </div>
  );
}

export default Register;
