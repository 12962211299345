import React, { useState, useEffect } from 'react';
import { MarketPlaceConnect } from '../connect/marketplace.connect';

function DailyComboList() {
    const [dailyCombos, setDailyCombos] = useState([]);
    const [numberOfDays, setNumberOfDays] = useState(0);
    const dailyCombo = new MarketPlaceConnect();

    const fetchDailyCombos = async () => {
        try {
            const { data: response } = await dailyCombo.allDailyCombos();
            console.log({ fetchDailyCombos: response });
            setDailyCombos(response);
        } catch (error) {
            const errorMessage = dailyCombo.handleTheError(error);
            alert(errorMessage);
            console.log({ errorMessage });
        }
    }

    const onEdit = (comboId) => {
        window.location = `/dailyCombo/edit/${comboId}`;
    }

    const onAddDailyCombos = async () => {
        try {
            const { data: response } = await dailyCombo.createDailyComboByNumber(numberOfDays);
            alert('Daily combos created successfully');
            fetchDailyCombos();
        } catch (error) {
            const errorMessage = dailyCombo.handleTheError(error);
            alert(errorMessage);
            console.log({ errorMessage });
        }
    }


    const deleteDailyCombo = async (combo_id) => {
        try {
        const { data: response } = await dailyCombo.dltDailyCombo(combo_id);
        if(response){
            console.log({ deleteDailyCombo: response });
            await fetchDailyCombos();
            return response;
        }
        } catch (error) {
            const errorMessage = dailyCombo.handleTheError(error);
            alert(errorMessage);
        }
    }

    useEffect(() => {
        fetchDailyCombos();
    }, []);

    return (
        <div className="max-w-sm mx-auto mt-10">
            <p className='text-white font-bold text-center text-[40px] mb-2'>Daily Combo List</p>
            <div className="mb-4">
                <input
                    type="number"
                    value={numberOfDays}
                    onChange={(e) => setNumberOfDays(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    placeholder="Enter number of days"
                />
                <button
                    onClick={onAddDailyCombos}
                    className="ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                    Add Daily Combos
                </button>
            </div>
            {dailyCombos.map((combo) => (
                <div key={combo._id} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 p-4 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                    <div className="mb-2">
                        <p className="text-lg font-semibold">Combo ID: {combo.combo_id}</p>
                        <p className="text-sm text-gray-500 dark:text-gray-400">Date: {new Date(combo.day).toLocaleDateString()}</p>
                        <p className="text-sm text-gray-500 dark:text-gray-400">Reward: {combo.reward}</p>
                    </div>
                    <div className="mb-2">
                        <p className="font-semibold">Combos:</p>
                        <ul className="list-disc list-inside">
                            {combo.combo.map(item => (
                                <li key={item.mine_id}>{item.mine_id}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex justify-between mt-4">
                        <button
                            onClick={() => onEdit(combo.combo_id)}
                            className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                        >
                            Edit
                        </button>

                        <button
                            onClick={() => deleteDailyCombo(combo.combo_id)}
                            className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DailyComboList;
