import React from 'react'

function CreateDailyReward() {
  return (
    <div>
      <p className='text-white font-bold text-center mt-8 m-2'> Page in progress: Use Add daily Combo to create daily combo </p>
    </div>
  )
}

export default CreateDailyReward