import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MarketPlaceConnect } from "../../connect/marketplace.connect";

function EditMine() {
  const marketPlaceConnect = new MarketPlaceConnect();
  const { mineId } = useParams();
  const [btnTxt, setBtnTxt] = useState("Edit Mine");
  const [btn, setBtn] = useState(false);
  const [project_id, setProjectId] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [typeAction, setTypeAction] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [telegramId, setTelegramId] = useState("");
  const [locked, setLocked] = useState(false);
  const [isReferralBased, setIsReferralBased] = useState({ referralCount: 0, increment: null });
  const [isLevelBased, setIsLevelBased] = useState({ mine_id: "", levelUp: 0 });
  const [isExpired, setIsExpired] = useState(false);
  const [mineLevels, setMineLevels] = useState([]);

  const editMine = async () => {
    try {
      setBtn(true);
      setBtnTxt("Editing...");
      const data = {
        mine_id: mineId,
        project_id,
        title,
        image,
        typeAction,
        category,
        description,
        telegramId,
        locked,
        isReferralBased: isReferralBased.referralCount ? isReferralBased : null,
        isLevelBased: isLevelBased.mine_id ? isLevelBased : null,
        isExpired,
        mineLevel: mineLevels,
      };
      // console.log({ data })
      const { data: response } = await marketPlaceConnect.editItem(data);
      if (response) {
        alert("Mine edited successfully");
      } else {
        alert("Something went wrong.");
      }
      setBtn(false);
      setBtnTxt("Edit Mine");
    } catch (error) {
      const errorMessage = marketPlaceConnect.handleTheError(error);
      alert(errorMessage);
      console.log({ errorMessage });
      setBtn(false);
      setBtnTxt("Edit Mine");
    }
  };

  const getMine = async () => {
    try {
      const { data: response } = await marketPlaceConnect.getMineById(mineId);
      if (response) {
        const mineData = response;
        setProjectId(mineData.project_id);
        setTitle(mineData.title);
        setImage(mineData.image);
        setTypeAction(mineData.typeAction);
        setCategory(mineData.category);
        setDescription(mineData.description);
        setTelegramId(mineData.telegramId);
        setLocked(mineData.locked);
        setIsReferralBased(mineData.isReferralBased || { referralCount: 0, increment: null });
        setIsLevelBased(mineData.isLevelBased || { mine_id: "", levelUp: 0 });
        setIsExpired(mineData.isExpired);
        setMineLevels(mineData.mineLevel);
      }
    } catch (error) {
      const errorMessage = marketPlaceConnect.handleTheError(error);
      alert(errorMessage);
      console.log({ errorMessage });
    }
  };

  useEffect(() => {
    getMine();
  }, [mineId]);

  const handleMineLevelChange = (index, field, value) => {
    const updatedLevels = [...mineLevels];
    updatedLevels[index][field] = value;
    setMineLevels(updatedLevels);
  };

  const addMineLevel = () => {
    setMineLevels([
      ...mineLevels,
      { level: 0, perHourGain: 0, cost: 0 },
    ]);
  };

  const removeMineLevel = (index) => {
    const updatedLevels = mineLevels.filter((_, idx) => idx !== index);
    setMineLevels(updatedLevels);
  };

  return (
    <form className="max-w-sm mx-auto mt-40">
      <p className="text-white font-bold text-center text-[40px] mb-2">
        Edit Mine
      </p>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Title
        </label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>
      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Image
        </label>
        <input
          type="text"
          value={image}
          onChange={(e) => setImage(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>
      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Type Action
        </label>
        <input
          type="text"
          value={typeAction}
          onChange={(e) => setTypeAction(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>
      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Category
        </label>
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="clan">Clan</option>
          <option value="project">Project</option>
          <option value="special">Special</option>
          <option value="characters">Characters</option>
        </select>
      </div>
      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Description
        </label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>
     
      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Locked
        </label>
        <input
          type="checkbox"
          checked={locked}
          onChange={(e) => setLocked(e.target.checked)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
        />
      </div>
      
      <div className="p-2 m-2 bg-slate-800 rounded-md mb-4">
      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Referral Count
        </label>
        <input
          type="number"
          value={isReferralBased.referralCount}
          onChange={(e) => setIsReferralBased({ ...isReferralBased, referralCount: parseInt(e.target.value, 10) })}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>
      </div>

      <div className="p-2 bg-slate-800 m-2 rounded-md">
      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Mine ID
        </label>
        <input
          type="text"
          value={isLevelBased.mine_id}
          onChange={(e) => setIsLevelBased({ ...isLevelBased, mine_id: e.target.value })}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>
      
      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Level Up
        </label>
        <input
          type="number"
          value={isLevelBased.levelUp}
          onChange={(e) => setIsLevelBased({ ...isLevelBased, levelUp: parseInt(e.target.value, 10) })}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>
      </div>

      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Expired
        </label>
        <input
          type="checkbox"
          checked={isExpired}
          onChange={(e) => setIsExpired(e.target.checked)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
        />
      </div>
      <div className="mb-4">
        <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Mine Levels
        </p>
        {mineLevels.map((level, index) => (
          <div key={index} className="mb-4 border p-2 rounded-lg">
            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Level
              </label>
              <input
                type="number"
                value={level.level}
                onChange={(e) =>
                  handleMineLevelChange(index, "level", parseInt(e.target.value, 10))
                }
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Per Hour Gain
              </label>
              <input
                type="number"
                value={level.perHourGain}
                onChange={(e) =>
                  handleMineLevelChange(index, "perHourGain", parseFloat(e.target.value))
                }
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Cost
              </label>
              <input
                type="number"
                value={level.cost}
                onChange={(e) =>
                  handleMineLevelChange(index, "cost", parseFloat(e.target.value))
                }
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <button
              type="button"
              onClick={() => removeMineLevel(index)}
              className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            >
              Remove Level
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={addMineLevel}
          className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        >
          Add Level
        </button>
      </div>
      <button
        type="button"
        disabled={btn}
        onClick={editMine}
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        {btnTxt}
      </button>
    </form>
  );
}

export default EditMine;
