import React, { useState, useEffect } from 'react';
import { LevelConnect } from '../../connect/level.connect';
import { useParams } from 'react-router-dom';


function LevelList() {
    const { projectId } = useParams();
    const level = new LevelConnect();
    const [ levels, setLevels ] = useState(null);

    const onEdit = (level_id) => {
        window.location = `/level/edit/${level_id}`
    }

    const getLevels = async () => {
        try {
            const { data: response } = await level.allLevels(projectId);
            if(response) {
                setLevels(response);
            }
        } catch (error) {
            console.log({ getLevels: error });
            alert(level.handleTheError(error));
        }
    }

    useEffect(() => {
        getLevels();
    }, []);

    return (
      <div className="max-w-sm mx-auto mt-10">
        <p className='text-white font-bold text-center text-[40px] mb-2'>Level List</p>
        { levels && levels.map((level) => (
          <div key={level._id} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 p-4 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <div className="flex items-center mb-2">
              <div>
                <img src={level.image} alt={level.name} className='w-10 h-10 rounded-full' />
                <p className="text-lg font-semibold">{level.name}</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">Level {level.lvl}</p>
              </div>
            </div>
            <p className="mb-2">Level Up: {level.levelUp}</p>
            <p className="mb-2">Description: {level.description}</p>
            <p className="mb-2">Multi Tap: {level.multiTap}</p>
            <p className="mb-2">Energy Limit: {level.energyLimit}</p>
            <p className="mb-2">Cost to Purchase Multi Tap: {level.costToPurchaseMultiTap}</p>
            <p className="mb-2">Cost to Purchase Energy Limit: {level.costToPurchaseEnergyLimit}</p>
            <p className="mb-2">Referral Bonus: {level.referralBonus}</p> 
            <p className="mb-2">Referral Bonus Premium: {level.referralBonusPremium}</p>
            <p className="mb-2">Level Up Bonus: {level.levelUpBonus}</p>
            <div className="flex justify-between">
              <button
                onClick={() => onEdit(level.level_id)}
                className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>
    );
}

export default LevelList