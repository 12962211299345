import { AxiosConnect } from "./axios.connect";

export class MarketPlaceConnect {

  // Market Place
  async createItem(data) {
    const { data: response } = await AxiosConnect.post(`/mine`, data);
    if (response && response.data) {
      return response;
    } else {
      alert("Network Error");
    }
  }

  async getAllItems() {
      const { data: response } = await AxiosConnect.get(`/mine/all`);
      if (response && response.data) {
      return response;
      } else {
      alert("Network Error");
      }
  }

  async editItem(data) {
    const { data: response } = await AxiosConnect.post(`/mine/update`, data);
    if (response && response.data) {
      return response;
    } else {
      alert("Network Error");
    }
  }

  async getMineById(mine_id) {
    const { data: response } = await AxiosConnect.get(`/mine/get/${mine_id}`);
    if (response && response.data) {
      return response;
    } else {
      alert("Network Error");
    }
  }

  async editItemLevel(data) {
    const { data: response } = await AxiosConnect.post(
      `/mine/edit-level`,
      data
    );
    if (response && response.data) {
      return response;
    } else {
      alert("Network Error");
    }
  }

  async deleteItem(mine_id) {
    const { data: response } = await AxiosConnect.post(`/mine/dlt/${mine_id}`);
    if (response && response.data) {
      return response;
    } else {
      alert("Network Error");
    }
  }

  async deleteItemLevel(mine_id, level) {
    const { data: response } = await AxiosConnect.post(
      `/mine/dlt-level/${mine_id}/${level}`
    );
    if (response && response.data) {
      return response;
    } else {
      alert("Network Error");
    }
  }

  async addItemLevel(data) {
    const { data: response } = await AxiosConnect.post(`/mine/add-level`, data);
    if (response && response.data) {
      return response;
    } else {
      alert("Network Error");
    }
  }

  // Daily Combo
  async createDailyCombo(data) {
    const { data: response } = await AxiosConnect.post(`/combo/create-combo`, data);
    if (response && response.data) {
      return response;
    }
  }

  async createDailyComboByNumber(numberOfDays) {
    const { data: response } = await AxiosConnect.get(`/combo/create-combo-days/${numberOfDays}`);
    if (response && response.data) {
      return response;
    }
  }

  async getCombo(combo_id) {
    const { data: response } = await AxiosConnect.get(`/combo/get-details/${combo_id}`);
    if (response && response.data) {
      return response;
    }
  }

  async editDailyCombo(data) {
    const { data: response } = await AxiosConnect.post(`/combo/edit-daily`, data);
    if (response && response.data) {
      return response;
    }
  }

  async dltDailyCombo(combo_id) {
    const { data: response } = await AxiosConnect.get(`/combo/dlt/${combo_id}`);
    if (response && response.data) {
      return response;
    }
  }

  async todaysDailyCombo() {
    const { data: response } = await AxiosConnect.get(`/combo/todays-combo`);
    if (response && response.data) {
      return response;
    }
  }

  async allDailyCombos() {
    const { data: response } = await AxiosConnect.get(`/combo/daily-combo`);
    if (response && response.data) {
      return response;
    }
  }

  async todaysUserDailyCombo(telegramId) {
    const { data: response } = await AxiosConnect.get(`/combo/user-daily-combo/${telegramId}`);
    if (response && response.data) {
      return response;
    }
  }

  async createMineLevel(data) {
    const { data: response } = await AxiosConnect.post(
      `/mine/add-quest-task-number`,
      data
    );
    if (response && response.data) {
      return response;
    }
  }




  handleTheError(AxiosError) {
    let errorMessage =
      AxiosError.response?.data?.message ||
      AxiosError.message ||
      "Request failed";
    console.error("Error message:", errorMessage);
    return errorMessage;
  }
}
