import React, { useState } from 'react';
import { LevelConnect } from '../connect/level.connect';
import { useParams } from 'react-router-dom';

function Level() {
   const level = new LevelConnect();
   const { projectId } =  useParams();
   const [ btnTxt, setBtnTxt ] = useState("Create Level");
   const [ btn, setBtn ] = useState(false);
   const [telegramId, setTelegramId] = useState("7112886149");
   const [lvl, setLvl] = useState(0);
   const [name, setName] = useState("");
   const [levelUp, setLevelUp] = useState(300);
   const [multiTap, setMultiTap] = useState(1);
   const [ increment, setIncrement ] = useState(false)
   const [energyLimit, setEnergyLimit] = useState(200);
   const [costToPurchaseMultiTap, setCostToPurchaseMultiTap] = useState(20);
   const [costToPurchaseEnergyLimit, setCostToPurchaseEnergyLimit] = useState(20);
   const [referralBonusPremium, setReferralBonusPremium] = useState(200);
   const [referralBonus, setReferralBonus] = useState(200);
   const [ levelImage, setLevelImage ] = useState("");
   const [ description, setDescription ] = useState("");

   const [ levelUpBonus, setLevelUpBonus ] = useState(0);

   const createLevel = async () => {
      try {
        setBtn(true);
        setBtnTxt("creating...");
         console.log('Project ID:', projectId);
         console.log('Telegram ID:', telegramId);
         console.log('Level:', lvl);
         console.log('Name:', name);
         console.log('Level Up:', levelUp);
         console.log('Multi Tap:', multiTap);
         console.log('Energy Limit:', energyLimit);
         console.log('Cost to Purchase Multi Tap:', costToPurchaseMultiTap);
         console.log('Cost to Purchase Energy Limit:', costToPurchaseEnergyLimit);
         console.log("Level Up Bonus", levelUpBonus);
         console.log("Description", description);
         const data = {
            project_id: projectId,
            telegramId,
            lvl,
            levelImage,
            name,
            levelUp,
            multiTap,
            energyLimit,
            costToPurchaseMultiTap,
            costToPurchaseEnergyLimit,
            referralBonus,
            referralBonusPremium,
            levelUpBonus,
            description
         };
         const { data: response } = await level.createLevel(data);
         if(response) {
            alert("Level created");
            setIncrement(increment + 1);
         } else {
            alert("Something went wrong.");
         }
         setBtn(false);
        setBtnTxt("Create");
      } catch (error) {
         console.log({ createLevel: error });
         alert(level.handleTheError(error));
         setBtn(false);
        setBtnTxt("Create");
      }
   };

   return (
      <form className="max-w-sm mx-auto mt-40">
         <p className='text-white font-bold text-center text-[40px] mb-2'>Create Level</p>
         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Telegram ID</label>
            <input
               type="text"
               value={telegramId}
               onChange={(e) => setTelegramId(e.target.value)}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>
         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Level
            <small><span className='p-1 m-0'>{ increment ? " (we've added +1 already)"  : "" }</span></small>
            </label>
            <input
               type="number"
               value={lvl}
               onChange={(e) => setLvl(parseFloat(e.target.value))}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>
         <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Image Link
              </label>
              <input
                type="text"
                value={levelImage}
                onChange={(e) => setLevelImage(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
            <input
               type="text"
               value={name}
               onChange={(e) => setName(e.target.value)}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>

         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
            <input
               type="text"
               value={description}
               onChange={(e) => setDescription(e.target.value)}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>

         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Level Up
            </label>
            <input
               type="number"
               value={levelUp}
               onChange={(e) => setLevelUp(parseFloat(e.target.value))}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>
         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Multi Tap</label>
            <input
               type="number"
               value={multiTap}
               onChange={(e) => setMultiTap(parseFloat(e.target.value))}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>
         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Energy Limit</label>
            <input
               type="number"
               value={energyLimit}
               onChange={(e) => setEnergyLimit(parseFloat(e.target.value))}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>
         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cost to Purchase Multi Tap</label>
            <input
               type="number"
               value={costToPurchaseMultiTap}
               onChange={(e) => setCostToPurchaseMultiTap(parseFloat(e.target.value))}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>
         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cost to Purchase Energy Limit</label>
            <input
               type="number"
               value={costToPurchaseEnergyLimit}
               onChange={(e) => setCostToPurchaseEnergyLimit(parseFloat(e.target.value))}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>

         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Referral Bonus Premuim</label>
            <input
               type="number"
               value={referralBonusPremium}
               onChange={(e) => setReferralBonusPremium(parseFloat(e.target.value))}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>

         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Referral Bonus </label>
            <input
               type="number"
               value={referralBonus}
               onChange={(e) => setReferralBonus(parseFloat(e.target.value))}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>

         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Level Up Bonus </label>
            <input
               type="number"
               value={levelUpBonus}
               onChange={(e) => setLevelUpBonus(parseFloat(e.target.value))}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>

         
         <button
            type="button"
            onClick={createLevel}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
         >
            { btnTxt }
         </button>
      </form>
   );
}

export default Level;
