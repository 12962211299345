import React, { useState, useEffect } from "react";
import { AdminConnect } from "../../connect/admin.connect";

function UserList() {
  const adminConnect = new AdminConnect();
  const [users, setUsers] = useState([]);
  const [sortBy, setSortBy] = useState("referralCount");
  const [isPremium, setIsPremium] = useState(undefined);
  const [minCoin, setMinCoin] = useState(undefined);
  const [makingRequest, setMakingRequest] = useState(false);
  const [maxCoin, setMaxCoin] = useState(undefined);
  const [identifier, setIdentifier] = useState("");
  const [ userLength, setUserLength ] = useState(0);

  const getUsers = async () => {
    try {
      await getUserCount()
      setMakingRequest(true);
      const response = await adminConnect.getUsers({
        sortBy,
        isPremium,
        minCoin,
        maxCoin,
      });
      console.log({ response });
      setUsers(response);
      setMakingRequest(false);
    } catch (error) {
      const errorMessage = adminConnect.handleTheError(error);
      setMakingRequest(false);
      console.error({ getUsers: errorMessage });
    }
  };

  const getUserCount = async () => {

    try {
      setMakingRequest(true);
      const response = await adminConnect.getCount();
      setUserLength(response?.data??0);
    } catch (error) {
      const errorMessage = adminConnect.handleTheError(error);
      console.error({ getUsers: errorMessage });
    }
  };

  const findUser = () => {
    window.location = `user/${encodeURI(identifier)}`;
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="max-w-sm mx-auto mt-10">
      <p className="text-white font-bold text-center text-[40px] mb-2">
        User List
      </p>
      <div className="mb-4">
        <label className="block text-white mb-2">Sort By:</label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
        >
          <option value="referralCount">Referral Count</option>
          <option value="lastBoostDate">Last Activity</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-white mb-2">Is Premium:</label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          value={isPremium}
          onChange={(e) =>
            setIsPremium(
              e.target.value === "" ? undefined : e.target.value === "true"
            )
          }
        >
          <option value="">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-white mb-2">Coin Range:</label>
        <input
          type="number"
          placeholder="Min Coin"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          value={minCoin}
          onChange={(e) =>
            setMinCoin(e.target.value ? parseInt(e.target.value) : undefined)
          }
        />
        <input
          type="number"
          placeholder="Max Coin"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          value={maxCoin}
          onChange={(e) =>
            setMaxCoin(e.target.value ? parseInt(e.target.value) : undefined)
          }
        />
      </div>
      <div className="grid justify-between p-2 m-2">
        <button
          className="px-6 py-2 m-2 text-white text-center rounded-lg bg-blue-800"
          onClick={() => getUsers()}
        >
          Filter
        </button>
        <div className="flex justify-between p-2">
          <input
            type="text"
            placeholder="telegramId or username"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
          />
          <button
            className="px-6 py-4 m-2 text-white text-center rounded-lg bg-blue-800"
            onClick={() => findUser()}
          >
            Get user
          </button>
        </div>
      </div>
      {users && users.length > 0 && (
        <p className="text-white font-semibold p-2 m-2 ">
          Users ({(users.length).toLocaleString()}), total users ({(userLength).toLocaleString()})
        </p>
      )}
      {users && makingRequest ? (
        <p className="text-white"> Making Request </p>
      ) : (
        users.map((user) => (
          <div
            key={user._id}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 p-4 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <div className="flex items-center mb-2">
              <div>
                <p className="text-lg font-semibold">{user.username}</p>
                <p className="text-lg font-semibold">{user.telegramId}</p>
                <p className="text-sm font-bold text-gray-500 dark:text-gray-400">
                  Referral Count:  <span className="font-bold text-white">{(user.referralCount).toLocaleString()}</span>
                </p>
                <p className="text-sm font-bold text-gray-500 dark:text-gray-400">
                  Coin: <span className="font-bold text-white">{(user.coin).toLocaleString()}</span>
                </p>
              </div>
            </div>
            <div className="flex justify-between">
                <a
                  href={`/user/${user.telegramId}`}
                  className="text-white cursor-pointer bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  User details
                </a>

                <a
                  href={`/referral/${user.telegramId}`}
                  className="text-white cursor-pointer bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Referrals
                </a>
              </div>
          </div>
        ))
      )}
    </div>
  );
}

export default UserList;
