import { AxiosConnect } from "./axios.connect";

export class AirdropConnect {
  async createAirdrop(data) {
    const { data: response } = await AxiosConnect.post(`/admins`, data);
    if (response && response.data) {
      return response;
    } else {
      alert("Network Error, could not create airdrop,");
    }
  }

  async allAirdrops() {
    const { data: response } = await AxiosConnect.get("/project/");
    if (response && response.data) {
      return response;
    } else {
      alert("Network Error, could not create airdrop,");
    }
  }

  async getAirdrop(project_id) {
    const { data: response } = await AxiosConnect.get(`/project/${project_id}`);
    if (response && response.data) {
      return response;
    } else {
      alert("Network Error, could not create airdrop,");
    }
  }

  async editAirdrop(data) {
    const { data: response } = await AxiosConnect.post(
      `/project/update/${data.project_id}`,
      data
    );
    if (response && response.data) {
      return response;
    } else {
      alert("Network Error, could not create airdrop,");
    }
  }

  handleTheError(AxiosError) {
    let errorMessage =
      AxiosError.response?.data?.message ||
      AxiosError.message ||
      "Request failed";
    console.error("Error message:", errorMessage);
    return errorMessage;
  }
}
