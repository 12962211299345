import { AxiosConnect } from "./axios.connect";

export class ProjectConnect {
  async createAirdrop(data) {
    const { data: response } = await AxiosConnect.post(`/airdrop`, data);
    if (response && response.data) {
      return response;
    }
  }

  async getAirdrop(airdrop_id) {
    const { data: response } = await AxiosConnect.get(`/airdrop/${airdrop_id}`);
    if (response && response.data) {
      return response;
    }
  }

  async getQuest(airdrop_id) {
    const { data: response } = await AxiosConnect.get(
      `/airdrop/quest/${airdrop_id}`
    );
    if (response && response.data) {
      return response;
    }
  }



  async getQuestById(questId) {
    const { data: response } = await AxiosConnect.get(
      `/airdrop/quest-detail/${questId}`
    );
    if (response && response.data) {
      return response;
    }
  }

  async createQuest(data) {
    const { data: response } = await AxiosConnect.post(
      `/airdrop/add-quest`,
      data
    );
    if (response && response.data) {
      return response;
    }
  }

  async airdropName() {
    const { data: response } = await AxiosConnect.get(`/airdrop/airdrop-name/all`);
    if (response && response.data) {
      return response;
    }
  }

  async editQuest(data) {
    const { data: response } = await AxiosConnect.post(
      `/airdrop/edit-quest/`,
      data
    );
    if (response && response.data) {
      return response;
    }
  }

  async editAirdrop(data) {
    const { data: response } = await AxiosConnect.post(`/airdrop/edit`, data);
    if (response && response.data) {
      return response;
    }
  }

  async allAirdrops() {
    const { data: response } = await AxiosConnect.get(`/airdrop/all`);
    if (response && response.data) {
      return response;
    }
  }

  handleTheError(AxiosError) {
    let errorMessage =
      AxiosError.response?.data?.message ||
      AxiosError.message ||
      "Request failed";
    console.error("Error message:", errorMessage);
    return errorMessage;
  }
}
