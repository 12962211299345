import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdminConnect } from "../../connect/admin.connect";

function GetUser() {
  const admin = new AdminConnect();
  const [users, setUsers] = useState(null);
  const { identifier } = useParams();

  const getUser = async () => {
    try {
      const { data: response } = await admin.getUserBy(identifier);
      if (response) {
        setUsers([response]);
      }
    } catch (error) {
      const errorMessage = admin.handleTheError(error);
      alert(errorMessage);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="max-w-sm mx-auto mt-10">
      {users &&
        users.map((user) => (
          <div
            key={user._id}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 p-4 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <div className="flex items-center mb-2 text-white font-bold">
              <div>
                <p className="text-lg font-semibold">{user.username}</p>
                <p className="text-lg font-semibold">{user.telegramId}</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Referral Count:{" "}
                  <span className="text-white">{(user.referralCount).toLocaleString()}</span>
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Coin: <span className="text-white">{(user.coin).toLocaleString()}</span>
                </p>

                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Earn Per Tap: <span className="text-white">{user.earnPerTap}</span>
                </p>

                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Daily Reward streaks: <span className="text-white">{user.currentStreak}</span>
                </p>

                <p className="text-sm text-gray-500 dark:text-gray-400">
                Multiplier Purchase Level: <span className="text-white">{user.lastMultiTapPurchaseLevel}</span>
                </p>

                <p className="text-sm text-gray-500 dark:text-gray-400">
                Energy Limit Purchase Level: <span className="text-white">{user.lastEnergyLimitPurchaseLevel}</span>
                </p>

                {user?.raider !== null && (
                  <>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Region:{" "}
                      <span className="text-white">{user.raider?.name}</span>
                    </p>

                    <p className="text-sm text-gray-500 dark:text-gray-400">
                     <img className="m-2 w-10 h-10 rounded-lg" src={user.raider?.image} /> 
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default GetUser;
