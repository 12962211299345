import React, { useEffect, useState } from "react";
import { AirdropConnect } from "../connect/airdrop.connect";
import ProjectList from "../components/airdrop/ProjectList";

function Project() {
  const airdrop = new AirdropConnect();
  const [btnTxt, setBtnTxt] = useState("Create");
  const [btn, setBtn] = useState(false);
  const [project_id, setProject_id] = useState(null);
  const [twitterLink, setTwitterLink] = useState("");
  const [telegramLink, setTelegramLink] = useState("");
  const [projects, setProjects] = useState(null);
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [image, setImage] = useState("");
  const [typeAction, setTypeAction] = useState("");
  const [category, setCategory] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [discordLink, setDiscordLink] = useState("");
  const [description, setDescription] = useState("");
  const [locked, setLocked] = useState(false);
  const [telegramFollowReward, setTelegramFollowReward] = useState(0);
  const [twitterFollowReward, setTwitterFollowReward] = useState(0);
  const [telegramChannelId, setTelegramChannelId] = useState("");
  const [levelImage, setLevelImage] = useState("");
  const [telegramChannelUsername, setTelegramChannelUsername] = useState("");
  const [addPost, setAddPost] = useState(false);

  const createAirdrop = async () => {
    try {
      setBtn(true);
      setBtnTxt("Creating...");
      console.log("Twitter Link:", twitterLink);
      console.log("Telegram Link:", telegramLink);
      console.log("Name:", name);
      console.log("Symbol:", symbol);
      console.log("Image:", image);
      console.log("Type Action:", typeAction);
      console.log("Category:", category);
      console.log("Instagram Link:", instagramLink);
      console.log("Discord Link:", discordLink);
      console.log("Description:", description);
      console.log("Locked:", locked);
      const data = {
        twitterLink,
        telegramLink,
        name,
        symbol,
        image,
        typeAction,
        category,
        instagramLink,
        discordLink,
        description,
        telegramFollowReward,
        twitterFollowReward,
        telegramChannelId,
        telegramChannelUsername,
        locked,
        levelImage,
      };
      if (
        !twitterLink ||
        !telegramLink ||
        !name ||
        !symbol ||
        !image ||
        !typeAction ||
        !category ||
        !instagramLink ||
        !discordLink ||
        !description
      ) {
        alert("Please fill in all fields.");
        return false;
      }
      const { data: response } = await airdrop.createAirdrop(data);
      if (response) {
        setBtn(false);
        setBtnTxt("Create");
        alert("Airdrop created.");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      setBtn(false);
      console.log({ createAirdrop: error });
      setBtnTxt("Create");
      alert(airdrop.handleTheError(error));
    }
  };

  const editAirdrop = async () => {
    try {
      setBtn(true);
      setBtnTxt("Creating...");
      console.log("Twitter Link:", twitterLink);
      console.log("Telegram Link:", telegramLink);
      console.log("Name:", name);
      console.log("Symbol:", symbol);
      console.log("Image:", image);
      console.log("Type Action:", typeAction);
      console.log("Category:", category);
      console.log("Instagram Link:", instagramLink);
      console.log("Discord Link:", discordLink);
      console.log("Description:", description);
      console.log("Locked:", locked);
      const data = {
        twitterLink,
        project_id,
        telegramLink,
        name,
        symbol,
        image,
        typeAction,
        category,
        instagramLink,
        discordLink,
        description,
        telegramFollowReward,
        twitterFollowReward,
        telegramChannelId,
        telegramChannelUsername,
        locked,
      };
      if (
        !twitterLink ||
        !telegramLink ||
        !name ||
        !symbol ||
        !image ||
        !typeAction ||
        !category ||
        !instagramLink ||
        !discordLink ||
        !description
      ) {
        alert("Please fill in all fields.");
        return false;
      }
      const { data: response } = await airdrop.editAirdrop(data);
      if (response) {
        setBtn(false);
        setBtnTxt("Edit");
        alert("Airdrop Edited.");
        window.location.reload();
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      setBtn(false);
      console.log({ createAirdrop: error });
      setBtnTxt("Edit");
      alert(airdrop.handleTheError(error));
    }
  };

  const handleAddLevel = (projectId) => {
    window.location = `/level/${projectId}`;
  };

  const handleEdit = (project) => {
    window.location = `/project/${project.project_id}`;
  };

  const getAllProjects = async () => {
    try {
      const { data: response } = await airdrop.allAirdrops();
      if (response) {
        console.log({ getAllProjects: response });
        setProjects(response);
      }
    } catch (error) {
      alert(airdrop.handleTheError(error));
      console.log({ getAllProjects: error });
    }
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  return (
    <div className="">
      {addPost ? (
        <div>
          <form className="max-w-sm mx-auto mt-40">
            <p className="text-white font-bold text-center text-[40px] mb-2">
              Airdrop
            </p>
            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Twitter Link
              </label>
              <input
                type="text"
                value={twitterLink}
                onChange={(e) => setTwitterLink(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Telegram Link
              </label>
              <input
                type="text"
                value={telegramLink}
                onChange={(e) => setTelegramLink(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Name
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Symbol
              </label>
              <input
                type="text"
                value={symbol}
                onChange={(e) => setSymbol(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Image
              </label>
              <input
                type="text"
                value={image}
                onChange={(e) => setImage(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Type Action
              </label>
              <input
                type="text"
                value={typeAction}
                onChange={(e) => setTypeAction(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Category
              </label>
              <input
                type="text"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Instagram Link
              </label>
              <input
                type="text"
                value={instagramLink}
                onChange={(e) => setInstagramLink(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Discord Link
              </label>
              <input
                type="text"
                value={discordLink}
                onChange={(e) => setDiscordLink(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Description
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={5}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Lock Project
              </label>
              <input
                type="checkbox"
                checked={locked}
                onChange={(e) => setLocked(e.target.checked)}
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
              />
            </div>

            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Twitter Follow Reward
                <small className="text-gray-400 m-0 p-1">
                  {" "}
                  amount given if following{" "}
                </small>
              </label>
              <input
                type="number"
                value={twitterFollowReward}
                onChange={(e) => setTwitterFollowReward(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Telegram Follow Reward
                <small className="text-gray-400 m-0 p-1">
                  {" "}
                  amount given if following{" "}
                </small>
              </label>
              <input
                type="number"
                value={telegramFollowReward}
                onChange={(e) => setTelegramFollowReward(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Telegram Channel ID
                <small className="text-blue-600 m-0 p-1">
                  <a href="">how to get ?</a>
                </small>
              </label>
              <input
                type="text"
                value={telegramChannelId}
                onChange={(e) => setTelegramChannelId(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Telegram Username
                <small className="text-blue-600 m-0 p-1">
                  <a href="">how to get ?</a>
                </small>
              </label>
              <input
                type="text"
                value={telegramChannelUsername}
                onChange={(e) => setTelegramChannelUsername(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <button
              type="button"
              disabled={btn}
              onClick={createAirdrop}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {btnTxt}
            </button>
          </form>
        </div>
      ) : (
        <>
          <div className="text-center m-2 p-2">
            <p className="text-white font-bold text-center text-[40px] mb-6">
              Project List
            </p>
            <div className="grid grid-cols-3 gap-4">
              <button
                className="px-6 py-2 text-white rounded-lg bg-blue-800"
                onClick={() => setAddPost(true)}
              >
                Create Airdrop
              </button>

              <a
                className="px-6 py-2 text-white text-center rounded-lg bg-blue-800"
                href="/users"
              >
                View Users
              </a>
              <a
                className="px-6 py-2 text-white text-center rounded-lg bg-blue-800"
                href="/register"
              >
                Add Admin
              </a>

              <a
                className="px-6 py-2 text-white text-center rounded-lg bg-blue-800"
                href="/marketplace"
              >
                Market Place
              </a>

              <a
                className="px-6 py-2 text-white text-center rounded-lg bg-blue-800"
                href="/airdrops"
              >
                Airdrop listing
              </a>

            </div>
          </div>

          <ProjectList
            projects={projects}
            onAddLevel={handleAddLevel}
            onEdit={handleEdit}
          />
        </>
      )}
    </div>
  );
}

export default Project;
