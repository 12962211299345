import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MarketPlaceConnect } from '../../connect/marketplace.connect';

function EditCombo() {
   const comboConnect = new MarketPlaceConnect();
   const { combo_id } = useParams();
   const [ btnTxt, setBtnTxt ] = useState("Edit Combo");
   const [ btn, setBtn ] = useState(false);
   const [ comboData, setComboData ] = useState({
      combo_id: '',
      combo: [{ mine_id: '' }],
      day: '',
      reward: 0,
   });
   const [numberOfDays, setNumberOfDays] = useState(0);

   const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

   const editCombo = async () => {
      try {
         setBtn(true);
         setBtnTxt("Editing...");

         const data = {
            ...comboData,
            combo_id,
            day: new Date(comboData.day),
         };
         const response = await comboConnect.editDailyCombo(data);
         if(response) {
            alert("Combo edited");
         } else {
            alert("Something went wrong.");
         }
         setBtn(false);
         setBtnTxt("Edit Combo");
      } catch (error) {
         console.log({ editCombo: error });
         alert(comboConnect.handleTheError(error));
         setBtn(false);
         setBtnTxt("Edit Combo");
      }
   };

   const getCombo = async () => {
     try {
        const { data:response } = await comboConnect.getCombo(combo_id);
        if(response) {
            setComboData(response);
            console.log({ combo_id, response})
        }
     } catch (error) {
        console.log({ getCombo: error });
        alert(comboConnect.handleTheError(error));
     }
   }

   useEffect(() => {
    getCombo();
   }, [combo_id]);

   const handleComboChange = (index, value) => {
      const updatedCombo = comboData.combo.map((comboItem, idx) => {
         if(idx === index) {
            return { ...comboItem, mine_id: value };
         }
         return comboItem;
      });
      setComboData({ ...comboData, combo: updatedCombo });
   };

   return (
      <form className="max-w-sm mx-auto mt-40">
         <p className='text-white font-bold text-center text-[40px] mb-2'>Edit Combo</p>

         {comboData && comboData?.combo?.map((comboItem, index) => (
            <div key={index} className="mb-2">
               <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mine ID ({index + 1})</label>
               <input
                  type="text"
                  value={comboItem.mine_id}
                  onChange={(e) => handleComboChange(index, e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
               />
            </div>
         ))}

         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Day</label>
            <input
               type="date"
               value={formatDate(comboData.day)}
               onChange={(e) => setComboData({ ...comboData, day: e.target.value })}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>

         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Reward</label>
            <input
               type="number"
               value={comboData.reward}
               onChange={(e) => setComboData({ ...comboData, reward: parseFloat(e.target.value) })}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>

         {/* <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Number of Days</label>
            <input
               type="number"
               value={numberOfDays}
               onChange={(e) => setNumberOfDays(parseFloat(e.target.value))}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div> */}

         <button
            type="button"
            disabled={btn}
            onClick={editCombo}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
         >
            { btnTxt }
         </button>
      </form>
   );
}

export default EditCombo;
