import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AirdropConnect } from "../../connect/airdrop.connect";

function ProjectEdit() {
  const { project_id } = useParams();
  const airdrop = new AirdropConnect();
  const [btnTxt, setBtnTxt] = useState("edit");
  const [btn, setBtn] = useState(false);
  const [twitterLink, setTwitterLink] = useState(
    ""
  );
  const [telegramLink, setTelegramLink] = useState("");
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [image, setImage] = useState(
    "https://pbs.twimg.com/profile_images/1745369307914047488/MVcQdhYp_400x400.jpg"
  );
  const [typeAction, setTypeAction] = useState("profitPerHour");
  const [category, setCategory] = useState("market");
  const [instagramLink, setInstagramLink] = useState(
    ""
  );
  const [discordLink, setDiscordLink] = useState(
    ""
  );
  const [description, setDescription] = useState(
    ""
  );
  const [locked, setLocked] = useState(false);
  const [telegramFollowReward, setTelegramFollowReward] = useState(0);
  const [twitterFollowReward, setTwitterFollowReward] = useState(0);
  const [telegramChannelId, setTelegramChannelId] =
    useState("DevToNatureTalent");
  const [telegramChannelUsername, setTelegramChannelUsername] =
    useState("0xChimdi");
  const [addPost, setAddPost] = useState(false);

  const editAirdrop = async () => {
    try {
      setBtn(true);
      setBtnTxt("Editing...");
      // console.log("Twitter Link:", twitterLink);
      // console.log("Telegram Link:", telegramLink);
      // console.log("Name:", name);
      // console.log("Symbol:", symbol);
      // console.log("Image:", image);
      // console.log("Type Action:", typeAction);
      // console.log("Category:", category);
      // console.log("Instagram Link:", instagramLink);
      // console.log("Discord Link:", discordLink);
      // console.log("Description:", description);
      // console.log("Locked:", locked);
      const data = {
        twitterLink,
        project_id,
        telegramLink,
        name,
        symbol,
        image,
        typeAction,
        category,
        instagramLink,
        discordLink,
        description,
        telegramFollowReward,
        twitterFollowReward,
        telegramChannelId,
        telegramChannelUsername,
        locked,
      };
      if (
        !twitterLink ||
        !telegramLink ||
        !name ||
        !symbol ||
        !image ||
        !typeAction ||
        !category ||
        !instagramLink ||
        !discordLink ||
        !description
      ) {
        alert("Please fill in all fields.");
        return false;
      }
      const { data: response } = await airdrop.editAirdrop(data);
      if (response) {
        setBtn(false);
        setBtnTxt("Edit");
        alert("Airdrop Edited.");
        window.location.reload();
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      setBtn(false);
      // console.log({ editAirdrop: error });
      setBtnTxt("Edit");
      alert(airdrop.handleTheError(error));
    }
  };

  const handleEdit = (project) => {
    setTwitterLink(project.twitterLink);
    setTelegramLink(project.telegramLink);
    setName(project.name);
    setSymbol(project.symbol);
    setImage(project.image);
    setTypeAction(project.typeAction);
    setCategory(project.category);
    setInstagramLink(project.instagramLink);
    setDiscordLink(project.discordLink);
    setDescription(project.description);
    setLocked(project.locked);
    setTelegramFollowReward(project.telegramFollowReward);
    setTwitterFollowReward(project.twitterFollowReward);
    setTelegramChannelId(project.telegramChannelId);
    setTelegramChannelUsername(project.telegramChannelUsername);
    setAddPost(true);
    setBtnTxt("Edit");
  };

  const getProject = async () => {
    try {
      const { data: response } = await airdrop.getAirdrop(project_id);
      if (response) {
        // console.log({ getProject: response });
        handleEdit(response)
      }
    } catch (error) {
      alert(airdrop.handleTheError(error));
      // console.log({ getProject: error });
    }
  };

  useEffect(() => {
    getProject();
  }, [project_id]);

  return (
    <div className="">
      <div>
        <form className="max-w-sm mx-auto mt-40">
          <p className="text-white font-bold text-center text-[40px] mb-2">
            Edit Airdrop
          </p>
          <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Twitter Link
            </label>
            <input
              type="text"
              value={twitterLink}
              onChange={(e) => setTwitterLink(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Telegram Link
            </label>
            <input
              type="text"
              value={telegramLink}
              onChange={(e) => setTelegramLink(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Name
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Symbol
            </label>
            <input
              type="text"
              value={symbol}
              onChange={(e) => setSymbol(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Image
            </label>
            <input
              type="text"
              value={image}
              onChange={(e) => setImage(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Type Action
            </label>
            <input
              type="text"
              value={typeAction}
              onChange={(e) => setTypeAction(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Category
            </label>
            <input
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Instagram Link
            </label>
            <input
              type="text"
              value={instagramLink}
              onChange={(e) => setInstagramLink(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Discord Link
            </label>
            <input
              type="text"
              value={discordLink}
              onChange={(e) => setDiscordLink(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Description
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={5}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Lock Project
            </label>
            <input
              type="checkbox"
              checked={locked}
              onChange={(e) => setLocked(e.target.checked)}
              className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
            />
          </div>

          <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Twitter Follow Reward
              <small className="text-gray-400 m-0 p-1">
                {" "}
                amount given if following{" "}
              </small>
            </label>
            <input
              type="number"
              value={twitterFollowReward}
              onChange={(e) => setTwitterFollowReward(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Telegram Follow Reward
              <small className="text-gray-400 m-0 p-1">
                {" "}
                amount given if following{" "}
              </small>
            </label>
            <input
              type="number"
              value={telegramFollowReward}
              onChange={(e) => setTelegramFollowReward(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Telegram Channel ID
              <small className="text-blue-600 m-0 p-1">
                <a href="">how to get ?</a>
              </small>
            </label>
            <input
              type="text"
              value={telegramChannelId}
              onChange={(e) => setTelegramChannelId(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Telegram Username
              <small className="text-blue-600 m-0 p-1">
                <a href="">how to get ?</a>
              </small>
            </label>
            <input
              type="text"
              value={telegramChannelUsername}
              onChange={(e) => setTelegramChannelUsername(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <button
            type="button"
            disabled={btn}
            onClick={async () => {
              if (project_id) {
                await editAirdrop();
              } else {
                await editAirdrop();
              }
            }}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            {btnTxt}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ProjectEdit;
