import React, { useState, useEffect } from 'react';
import { ProjectConnect } from '../connect/project.connect';

function AirdropList() {
    const [airdrops, setAirdrops] = useState([]);
    const airdrop = new ProjectConnect();

    const fetchAirdrops = async () => {
        try {
            const { data: response } = await airdrop.allAirdrops();
            console.log({ fetchAirdrops: response });
            setAirdrops(response);
        } catch (error) {
            const errorMessage = airdrop.handleTheError(error);
            alert(errorMessage);
            console.log({ errorMessage });
        }
    }

    const onEdit = (airdropId) => {
        window.location = `/airdrop/${airdropId}`;
    }

    const onView = (airdropId) => {
        window.location = `/quest/${airdropId}`;
    }

    const onAdd = (airdropId) => {
      window.location = `/quest/add/${airdropId}`;
    }

    const onDistribution = (airdropId) => {
        window.location = `/distribution/${airdropId}`;
    }

    useEffect(() => {
        fetchAirdrops();
    }, []);

    return (
        <div className="max-w-sm mx-auto mt-10">
            <p className='text-white font-bold text-center text-[40px] mb-2'>Airdrop List</p>
            <div className="grid grid-cols-3 gap-4">
              <a
                className="px-6 py-2 text-white text-center rounded-lg bg-blue-800"
                href="/airdrop"
              >
                Create Airdrop
              </a>

            </div>
            {airdrops.map((airdrop) => (
                <div key={airdrop._id} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 p-4 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                    <div className="flex items-center mb-2">
                        <img src={airdrop.logo} alt={airdrop.name} className="w-10 h-10 rounded-full mr-4" />
                        <div>
                            <p className="text-lg font-semibold">{airdrop.name}</p>
                            <p className="text-sm text-gray-500 dark:text-gray-400">{airdrop.description}</p>
                        </div>
                    </div>
                    <img src={airdrop.image} alt={airdrop.name} className="w-full h-40 object-cover mb-4 rounded" />
                    <p className="mb-2">{airdrop.about}</p>
                    <p className="mb-2">Status: {airdrop.status}</p>
                    <p className="mb-2">Verified: {airdrop.verified ? 'Yes' : 'No'}</p>
                    <p className="mb-2">Profit Per Hour: {(airdrop?.profitPerHour ?? 0).toLocaleString()}</p>
                    <p className="mb-2">Symbol: <span className='font-bold'>{airdrop.symbol}</span></p>
                    <p className="mb-2">Reward Pool: {(airdrop?.rewardPool ?? 0).toLocaleString()}</p>
                    <p className="mb-2">Reward Pool Amount: {(airdrop?.rewardPoolAmount ?? 0).toLocaleString()}</p>
                    <p className="mb-2">Total Raiders: {(airdrop?.totalUsers ?? 0).toLocaleString()}</p>
                    <p className="mb-2">Cost: {(airdrop?.cost ?? 0).toLocaleString()}</p>
                    <p className="mb-2">Start Date: {new Date(airdrop.startDate).toLocaleString()}</p>
                    <p className="mb-2">End Date: {new Date(airdrop.endDate).toLocaleString()}</p>
                    <div className="mb-2">
                        <p className="font-semibold">Reward Steps:</p>
                        <ul className="list-disc list-inside">
                            {airdrop.rewardSteps.map(step => (
                                <li key={step._id}>{step.description}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex justify-between mt-4">
                        <button
                            onClick={() => onEdit(airdrop.airdrop_id)}
                            className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                        >
                            Edit
                        </button>
                        <button
                            onClick={() => onView(airdrop.airdrop_id)}
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            View Quest
                        </button>

                        <button
                            onClick={() => onAdd(airdrop.airdrop_id)}
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Add Quest
                        </button>
                    </div>

                    <div className="flex justify-between mt-4">
                    <button
                            onClick={() => onDistribution(airdrop.airdrop_id)}
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Distribution
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default AirdropList;
