import React from 'react'
import Register from '../components/admin/createAdmin'

function RegisterAdmin() {
  return (
    <div>
        <Register />
    </div>
  )
}

export default RegisterAdmin