import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { MarketPlaceConnect } from "../../connect/marketplace.connect";

function CreateMine() {
  const mineService = new MarketPlaceConnect();
  const { projectId } = useParams();
  const [btnTxt, setBtnTxt] = useState("Create Mine");
  const [btn, setBtn] = useState(false);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [typeAction, setTypeAction] = useState("");
  const [category, setCategory] = useState("clan");
  const [description, setDescription] = useState("");
  const [mineLevels, setMineLevels] = useState([
    { level: 0, perHourGain: 0, cost: 0 },
  ]);

  const handleMineLevelChange = (index, event) => {
    const newMineLevels = [...mineLevels];
    newMineLevels[index][event.target.name] = event.target.value;
    setMineLevels(newMineLevels);
  };

  const addMineLevel = () => {
    setMineLevels([
      ...mineLevels,
      { level: mineLevels.length, perHourGain: 0, cost: 0 },
    ]);
  };

  const removeMineLevel = (index) => {
    const newMineLevels = mineLevels.filter((_, i) => i !== index);
    setMineLevels(newMineLevels);
  };

  const createMine = async () => {
    try {
      setBtn(true);
      setBtnTxt("Creating...");
      // console.log("Project ID:", projectId);
      // console.log("Title:", title);
      // console.log("Mine Levels:", mineLevels);

      const data = {
        title,
        telegramId: "7112886149",
        image,
        typeAction,
        category,
        description,
        mineLevel: mineLevels,
      };
      // console.log({ data });

      const { data: response } = await mineService.createItem(data);
      if (response) {
        alert("Mine created successfully");
      } else {
        alert("Something went wrong.");
      }
      setBtn(false);
      setBtnTxt("Create Mine");
    } catch (error) {
      const errorMessage = mineService.handleTheError(error);
      alert(errorMessage);
      console.log({ errorMessage });
      setBtn(false);
      setBtnTxt("Create Mine");
    }
  };

  return (
    <form className="max-w-sm mx-auto mt-40">
      <p className="text-white font-bold text-center text-[40px] mb-2">
        Create Mine
      </p>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Title
        </label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Image URL
        </label>
        <input
          type="text"
          value={image}
          onChange={(e) => setImage(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Type Action
        </label>
        <input
          type="text"
          value={typeAction}
          onChange={(e) => setTypeAction(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Category
        </label>
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="clan">Clan</option>
          <option value="project">Project</option>
          <option value="special">Special</option>
          <option value="characters">Characters</option>
        </select>
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Description
        </label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="bg-slate-800 mt-4 mb-4 p-2 rounded-sm">
        {mineLevels.map((level, index) => (
          <div key={index} className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Level
            </label>
            <input
              type="number"
              name="level"
              value={level.level}
              onChange={(e) => handleMineLevelChange(index, e)}
              placeholder="Level"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Profit Per Hour Gain
            </label>
            <input
              type="number"
              name="perHourGain"
              value={level.perHourGain}
              onChange={(e) => handleMineLevelChange(index, e)}
              placeholder="Per Hour Gain"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />

            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Cost
            </label>
            <input
              type="number"
              name="cost"
              value={level.cost}
              onChange={(e) => handleMineLevelChange(index, e)}
              placeholder="Cost"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            <button
              type="button"
              onClick={() => removeMineLevel(index)}
              className="text-red-600 hover:text-red-800 focus:outline-none"
            >
              Remove Level
            </button>
          </div>
        ))}
      </div>

      <button
        type="button"
        onClick={addMineLevel}
        className="text-blue-600 hover:text-blue-800 focus:outline-none"
      >
        Add Level
      </button>
      <button
        type="button"
        disabled={btn}
        onClick={createMine}
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-4"
      >
        {btnTxt}
      </button>
    </form>
  );
}

export default CreateMine;
