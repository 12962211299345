import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectConnect } from '../connect/project.connect';

function QuestList() {
    const { airdropId } = useParams();
    const questConnect = new ProjectConnect();
    const [quests, setQuests] = useState(null);

    const onEdit = (questId) => {
        window.location = `/quest/edit/${questId}`;
    };

    const getQuests = async () => {
        try {
            const { data: response } = await questConnect.getQuest(airdropId);
            if (response) {
                console.log({ getQuests: response });
                setQuests(response);
            }
        } catch (error) {
            console.log({ getQuests: error });
            alert(questConnect.handleTheError(error));
        }
    };

    useEffect(() => {
        getQuests();
    }, []);

    return (
        <div className="max-w-sm mx-auto mt-10">
            <p className="text-white font-bold text-center text-[40px] mb-2">Quest List</p>
            {quests && quests.map((quest) => (
                <div key={quest.quest_id} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 p-4 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                    <div className="flex items-center mb-2">
                        <div>
                            <p className="text-lg font-semibold">Quest ID: {quest.quest_id}</p>
                            <p className="text-sm text-gray-500 dark:text-gray-400">Level: {quest.level}</p>
                            <p className="text-sm text-gray-500 dark:text-gray-400">Airdrop ID: {quest.airdrop_id}</p>
                        </div>
                    </div>
                    <div className="mb-2">
                        <p className="font-semibold">Quest Tasks:</p>
                        <ul className="list-disc list-inside">
                            {quest.questTask.map(task => (
                                <li key={task.quest_task_id}>
                                    <p>{task.text}</p>
                                    <p>Action: {task.action}</p>
                                    <p>Timer: {task?.timer}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex justify-between mt-4">
                        <button
                            onClick={() => onEdit(quest.quest_id)}
                            className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                        >
                            Edit
                        </button>

                    </div>
                </div>
            ))}
        </div>
    );
}

export default QuestList;
