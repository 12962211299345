import React, { useState, useEffect } from "react";
import { ProjectConnect } from "../../connect/project.connect";

function AirdropCreate() {
  const airdrop = new ProjectConnect();
  const [btnTxt, setBtnTxt] = useState("Create Airdrop");
  const [btn, setBtn] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [about, setAbout] = useState("");
  const [image, setImage] = useState("");
  const [logo, setLogo] = useState("");
  const [status, setStatus] = useState("active");
  const [verified, setVerified] = useState(false);
  const [profitPerHour, setProfitPerHour] = useState(0);
  const [symbol, setSymbol] = useState("");
  const [ rewardPool, setRewardPool ] = useState(0);
  const [ rewardPoolAmount, setRewardPoolAmount ] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [cost, setCost] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rewardSteps, setRewardSteps] = useState([{ description: "" }]);
  const [conditions, setConditions] = useState({
    airdrop_id: "No_airdrop_default",
    questLevel: 0,
  });

  const [quest, setQuest] = useState({
    quest_task_id: "",
    text: "",
    action: "TELEGRAM",
    link:"",
    timer: 0,
  });
  const [airdropNames, setAirdropNames] = useState(null);
  
  const getAirdropNames = async () => {
    try {
      const { data: response } = await airdrop.airdropName();
      if (response) {
        console.log({ getAirdropNames: response });
        setAirdropNames(response);
      }
    } catch (error) {
      const errorMessage = airdrop.handleTheError(error);
      console.log({ errorMessage });
    }
  };

  useEffect(() => {
    getAirdropNames();
  }, []);

  const createAirdrop = async () => {
    try {
      setBtn(true);
      setBtnTxt("Creating...");
      const data = {
        name,
        description,
        about,
        image,
        logo,
        status,
        verified,
        profitPerHour,
        symbol,
        rewardPool,
        rewardPoolAmount,
        cost,
        startDate,
        endDate,
        rewardSteps,
        conditions,
        quest: {
          level: 0,
          questTask: [quest]
        }
      };
      console.log({ data })
      const { data: response } = await airdrop.createAirdrop(data);
      if (response) {
        alert("Airdrop created");
      } else {
        alert("Something went wrong.");
      }
      setBtn(false);
      setBtnTxt("Create Airdrop");
    } catch (error) {
      console.log({ errorMessage: airdrop.handleTheError(error) });
      alert(airdrop.handleTheError(error));
      setBtn(false);
      setBtnTxt("Create Airdrop");
    }
  };

  return (
    <form className="max-w-sm mx-auto mt-40">
      <p className="text-white font-bold text-center text-[40px] mb-2">
        Create Airdrop
      </p>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Name
        </label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Description
        </label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          About
        </label>
        <textarea
          value={about}
          onChange={(e) => setAbout(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Image Link
        </label>
        <input
          type="text"
          value={image}
          onChange={(e) => setImage(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Logo Link
        </label>
        <input
          type="text"
          value={logo}
          onChange={(e) => setLogo(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Status
        </label>
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="active">Active</option>
          <option value="ended">Ended</option>
          <option value="suspended">Suspended</option>
          <option value="locked">Locked</option>
        </select>
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Verified
        </label>
        <select
          value={verified}
          onChange={(e) => setVerified(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value={false}>Not Verified</option>
          <option value={true}>Verified</option>
        </select>
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Profit Per Hour
        </label>
        <input
          type="number"
          value={profitPerHour}
          onChange={(e) => setProfitPerHour(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Symbol
        </label>
        <input
          type="text"
          value={symbol}
          onChange={(e) => setSymbol(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Reward Pool
        </label>
        <input
          type="number"
          value={rewardPool}
          onChange={(e) => setRewardPool(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>


      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Reward Pool Amount
        </label>
        <input
          type="number"
          value={rewardPoolAmount}
          onChange={(e) => setRewardPoolAmount(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>


      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Cost
        </label>
        <input
          type="number"
          value={cost}
          onChange={(e) => setCost(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Start Date
        </label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          End Date
        </label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Reward Steps
        </label>
        {rewardSteps.map((step, index) => (
          <div key={index} className="mb-2">
            <input
              type="text"
              value={step.description}
              onChange={(e) => {
                const newRewardSteps = [...rewardSteps];
                newRewardSteps[index].description = e.target.value;
                setRewardSteps(newRewardSteps);
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        ))}
        <button
          type="button"
          onClick={() => setRewardSteps([...rewardSteps, { description: "" }])}
          className="bg-blue-500 text-white p-2 rounded-lg"
        >
          Add Step
        </button>
      </div>

      {/* <div className="mb-2 mt-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Conditions
        </label>
        <div className="mb-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Quest Level
          </label>
          <input
            type="number"
            value={conditions.level}
            onChange={(e) =>
              setConditions({ ...conditions, level: e.target.value })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        <div className="mb-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Airdrop ID
          </label>
          <input
            type="text"
            value={conditions.airdrop_id}
            onChange={(e) =>
              setConditions({ ...conditions, airdrop_id: e.target.value })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </div> */}

      <div className="mb-2 m-2 p-2 bg-slate-800 rounded-md">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Conditions
        </label>

        <div className="mb-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Airdrop ID
          </label>
          <select
            value={conditions.airdrop_id}
            onChange={(e) =>
              setConditions({ ...conditions, airdrop_id: e.target.value })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value={"No_airdrop_default"}> No airdrop </option>
            {airdropNames &&
              airdropNames.map((airdrop) => (
                <option value={airdrop.airdrop_id}> {airdrop.name} </option>
              ))}
          </select>
        </div>

        <div className="mb-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Quest Level
          </label>
          <input
            type="number"
            value={conditions.questLevel}
            onChange={(e) =>
              setConditions({ ...conditions, questLevel: e.target.value })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

      </div>

      <div className="mb-2 mt-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Single Quest Task
        </label>
        <div className="mb-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Quest Task ID
          </label>
          <input
            type="number"
            value={quest.questLevel}
            onChange={(e) =>
              setQuest({ ...quest, quest_task_id: e.target.value })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        <div className="mb-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Text
          </label>
          <input
            type="text"
            value={quest.text}
            onChange={(e) => setQuest({ ...quest, text: e.target.value })}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        <div className="mb-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Link
          </label>
          <input
            type="text"
            value={quest.link}
            onChange={(e) => setQuest({ ...quest, link: e.target.value })}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        

        <div className="mb-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Action
          </label>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={quest.action}
            onChange={(e) => setQuest({ ...quest, action: e.target.value })}
          >
            <option value={"TELEGRAM"}>Telegram</option>
            <option value={"TWITTER"}>Twitter</option>
            <option value={"INSTAGRAM"}>Instagram</option>
          </select>
        </div>

        <div className="mb-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Timer
          </label>
          <input
            type="number"
            value={quest.timer}
            onChange={(e) => setQuest({ ...quest, timer: e.target.value })}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </div>

      <button
        type="button"
        onClick={createAirdrop}
        disabled={btn}
        className="bg-blue-500 text-white p-2 rounded-lg"
      >
        {btnTxt}
      </button>
    </form>
  );
}

export default AirdropCreate;
