import React, { useState } from 'react';
import { RewardConnect } from '../connect/reward.connect';

function Reward() {
   const rewardConnect  = new RewardConnect();
   const [rewardId, setRewardId] = useState(1);
   const [projectId, setProjectId] = useState("1ed298df-b019-45dd-92c4-8c24ed289f2d");
   const [telegramId, setTelegramId] = useState("7112886149");
   const [day, setDay] = useState(1);
   const [image, setImage] = useState("https://pbs.twimg.com/profile_images/1721059726052749312/KDq0pNfO_400x400.jpg");
   const [reward, setReward] = useState(200);
   const [btnTxt, setBtnTxt] = useState("Create Reward");
   const [ btn, setBtn ] = useState(false);

   const createReward = async () => {
      try {
         setBtnTxt("creating...");
         setBtn(true);
         console.log('Reward ID:', rewardId);
         console.log('Project ID:', projectId);
         console.log('Telegram ID:', telegramId);
         console.log('Day:', day);
         console.log('Image:', image);
         console.log('Reward:', reward);
         const data = {
            reward_id: rewardId,
            project_id: projectId,
            telegramId,
            day,
            image,
            reward
         };
         const { data: response } = await rewardConnect.createReward(data);
         if(response) {
         setBtnTxt("Create");
         setBtn(false);
         alert("Reward Created")
         }   
      } catch (error) {
         console.log({ createReward: error });
         setBtnTxt("Create");
         setBtn(false);
         alert(rewardConnect.handleTheError(error));
      }
   };

   return (
      <form className="max-w-sm mx-auto mt-40">
         <p className='text-white font-bold text-center text-[40px] mb-2'>Create Daily Reward</p>
         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Reward ID</label>
            <input
               type="number"
               value={rewardId}
               onChange={(e) => setRewardId(parseFloat(e.target.value))}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>
         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Project ID (Disabled)</label>
            <input
               type="text"
               value={projectId}
               disabled={true}
               onChange={(e) => setProjectId(e.target.value)}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>
         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Telegram ID</label>
            <input
               type="text"
               value={telegramId}
               onChange={(e) => setTelegramId(e.target.value)}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>
         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Day</label>
            <input
               type="number"
               value={day}
               onChange={(e) => setDay(parseFloat(e.target.value))}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>
         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Image URL</label>
            <input
               type="text"
               value={image}
               onChange={(e) => setImage(e.target.value)}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>
         <div className="mb-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Reward</label>
            <input
               type="number"
               value={reward}
               onChange={(e) => setReward(parseFloat(e.target.value))}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
         </div>
         <button
            type="button"
            onClick={createReward}
            disabled={btn}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
         >
            { btnTxt }
         </button>
      </form>
   );
}

export default Reward;
