const { AxiosConnect } = require("./axios.connect");

export class Distribution {
    async getAirdropDetails(airdrop_id) {
        const { data: response } = await AxiosConnect.get(
          `/distribution/details/${airdrop_id}`
        );
        if (response && response.data) {
          return response;
        }
    }

    handleTheError(AxiosError) {
        let errorMessage =
          AxiosError.response?.data?.message ||
          AxiosError.message ||
          "Request failed";
        console.error("Error message:", errorMessage);
        return errorMessage;
    }
}