import axios from "axios"

export const AxiosConnect = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

AxiosConnect.interceptors.request.use(
    function (config) {
        const token = sessionStorage.getItem("token") || "";
        const email = sessionStorage.getItem("email");
        if (token) {
            
            config.headers.Authorization = `Bearer ${token}`;
            config.headers.email = email;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
