import React, { useState } from "react";
import { AdminConnect } from "../connect/admin.connect";

function Login() {
  const admin = new AdminConnect();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [ btn, setBtn ] = useState(false);
  const [ btnTxt, setBtnTxt ] = useState("Login")


  const LoginDetails = async () => {
        try {
         const seed = "seed"
         const { data: response } = await admin.Login({ email, otp: code, seed });
         if(response) {
            const email = response?.adminData.email;
            const token = response?.newToken;
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("email", email);
            console.log({ login: response});
            window.location = "/p";
         }
        } catch (error) {
        const errorMessage = admin.handleTheError(error);
        alert(errorMessage);
        }
  }


  return (
    <div>
      <form className="max-w-sm mx-auto mt-40">
        <p className="text-white font-bold text-center text-[40px] mb-2">
          Login
        </p>
        <div className="mb-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Email
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        <div className="mb-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Password
          </label>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        <button
              type="button"
              disabled={btn}
              onClick={LoginDetails}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {btnTxt}
            </button>
      </form>
    </div>
  );
}

export default Login;
