import React, { useEffect, useState } from "react";
import { AdminConnect } from "../../connect/admin.connect";
import { useParams } from "react-router-dom";

function Referral() {
  const admin = new AdminConnect();
  const [referrals, setReferrals] = useState(null);
  const [referralSum, setReferralSum] = useState(0);
  const { telegramId } = useParams();

  async function sumGains(response) {
    return await response.reduce((acc, obj) => acc + (obj.gain || 0), 0);
  }

  const getReferrals = async () => {
    try {
      const { data: response } = await admin.getReferrals(telegramId);
      if (response) {
        setReferrals(response);
        const sumOfReferrals = await sumGains(response);
        setReferralSum(sumOfReferrals);
      }
    } catch (error) {
      const errorMessage = admin.handleTheError(error);
      alert(errorMessage);
    }
  };

  useEffect(() => {
    getReferrals();
  }, []);

  return (
    <div className="max-w-sm mx-auto mt-10">
      <p className="text-white font-bold m-2 mb-4 p-2">Total referral Gain: {referralSum.toLocaleString()} </p>
      {referrals && referrals.length > 0 ? (
        referrals.map((user) => (
          <div
            key={user._id}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 p-4 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <div className="flex items-center mb-2">
              <div className="font-bold text-white">
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Referred user id:{" "}
                  <span className="text-white">{user.telegramId}</span>
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Gain:{" "}
                  <span className="text-white">
                    {user.gain.toLocaleString()}
                  </span>
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Claimed:{" "}
                  <span className="text-white">
                    {user.isClaimedByReferrer ? "YES" : "NO"}
                  </span>
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Claimed by user:{" "}
                  <span className="text-white">
                    {user.isClaimedByUser ? "YES" : "NO"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="m-2 mt-10 text-white text-center font-bold">
          No referral
        </div>
      )}
    </div>
  );
}

export default Referral;
