import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AirdropConnect } from "../../connect/airdrop.connect";
import { ProjectConnect } from "../../connect/project.connect";

function AirdropEdit() {
  const airdrop = new ProjectConnect();
  const { airdrop_id } = useParams();
  const [btnTxt, setBtnTxt] = useState("Edit Airdrop");
  const [btn, setBtn] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [about, setAbout] = useState("");
  const [image, setImage] = useState("");
  const [logo, setLogo] = useState("");
  const [status, setStatus] = useState("active");
  const [verified, setVerified] = useState(false);
  const [profitPerHour, setProfitPerHour] = useState(0);
  const [symbol, setSymbol] = useState("");
  const [rewardPool, setRewardPool] = useState(0);
  const [rewardPoolAmount, setRewardPoolAmount ] = useState(0);
  const [cost, setCost] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rewardSteps, setRewardSteps] = useState([{ description: "" }]);
  const [conditions, setConditions] = useState({
    airdrop_id: "",
    questLevel: 0,
  });
  const [airdropNames, setAirdropNames] = useState(null);

  const editAirdrop = async () => {
    try {
      setBtn(true);
      setBtnTxt("Editing...");
      const data = {
        airdrop_id,
        name,
        description,
        about,
        image,
        logo,
        status,
        verified,
        profitPerHour,
        symbol,
        rewardPool,
        rewardPoolAmount,
        cost,
        startDate,
        endDate,
        rewardSteps,
        conditions,
      };
      // console.log({ startDate, endDate });
      const { data: response } = await airdrop.editAirdrop(data);
      if (response) {
        alert("Airdrop edited");
      } else {
        alert("Something went wrong.");
      }
      setBtn(false);
      setBtnTxt("Edit Airdrop");
    } catch (error) {
      console.log({ editAirdrop: error });
      alert(airdrop.handleTheError(error));
      setBtn(false);
      setBtnTxt("Edit Airdrop");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getAirdrop = async () => {
    try {
      const { data: response } = await airdrop.getAirdrop(airdrop_id);
      if (response) {
        const airdropData = response?.airdrop;
        setName(airdropData.name);
        setDescription(airdropData.description);
        setAbout(airdropData.about);
        setImage(airdropData.image);
        setLogo(airdropData.logo);
        setStatus(airdropData.status);
        setVerified(airdropData.verified);
        setProfitPerHour(airdropData.profitPerHour);
        setSymbol(airdropData.symbol);
        setRewardPool(airdropData.rewardPool);
        setRewardPoolAmount(airdropData.rewardPoolAmount??0);
        setCost(airdropData.cost);
        setStartDate(formatDate(airdropData.startDate));
        setEndDate(formatDate(airdropData.endDate));
        setRewardSteps(airdropData.rewardSteps);
        setConditions(airdropData.conditions);
      }
    } catch (error) {
      console.log({ getAirdrop: error });
      alert(airdrop.handleTheError(error));
    }
  };

  const getAirdropNames = async () => {
    try {
      const { data: response } = await airdrop.airdropName();
      if (response) {
        // console.log({ getAirdropNames: response });
        setAirdropNames(response);
      }
    } catch (error) {
      const errorMessage = airdrop.handleTheError(error);
      console.log({ errorMessage });
    }
  };

  useEffect(() => {
    getAirdropNames();
  }, []);

  useEffect(() => {
    getAirdrop();
  }, [airdrop_id]);

  return (
    <form className="max-w-sm mx-auto mt-40">
      <p className="text-white font-bold text-center text-[40px] mb-2">
        Edit Airdrop
      </p>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Name
        </label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Description
        </label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          About
        </label>
        <textarea
          value={about}
          onChange={(e) => setAbout(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Image Link
        </label>
        <input
          type="text"
          value={image}
          onChange={(e) => setImage(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Logo Link
        </label>
        <input
          type="text"
          value={logo}
          onChange={(e) => setLogo(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Status
        </label>
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="active">Active</option>
          <option value="ended">Ended</option>
          <option value="suspended">Suspended</option>
          <option value="locked">Locked</option>
        </select>
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Verified
        </label>
        <select
          value={verified}
          onChange={(e) => setVerified(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value={false}>Not Verified</option>
          <option value={true}>Verified</option>
        </select>
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Profit Per Hour
        </label>
        <input
          type="number"
          value={profitPerHour}
          onChange={(e) => setProfitPerHour(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Symbol
        </label>
        <input
          type="text"
          value={symbol}
          onChange={(e) => setSymbol(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Reward Pool
        </label>
        <input
          type="number"
          value={rewardPool}
          onChange={(e) => setRewardPool(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Reward Pool Amount
        </label>
        <input
          type="number"
          value={rewardPoolAmount}
          onChange={(e) => setRewardPoolAmount(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Cost
        </label>
        <input
          type="number"
          value={cost}
          onChange={(e) => setCost(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Start Date
        </label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          End Date
        </label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Reward Steps
        </label>
        {rewardSteps &&
          rewardSteps.map((step, index) => (
            <div key={index} className="mb-2">
              <input
                type="text"
                value={step.description}
                onChange={(e) => {
                  const newRewardSteps = [...rewardSteps];
                  newRewardSteps[index].description = e.target.value;
                  setRewardSteps(newRewardSteps);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          ))}
        <button
          type="button"
          onClick={() => setRewardSteps([...rewardSteps, { description: "" }])}
          className="bg-blue-500 text-white p-2 rounded-lg"
        >
          Add Step
        </button>
      </div>

      <div className="mb-2 m-2 p-2 bg-slate-800 rounded-md">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Conditions
        </label>

        <div className="mb-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Airdrop ID
          </label>
          <select
            value={conditions.airdrop_id}
            onChange={(e) =>
              setConditions({ ...conditions, airdrop_id: e.target.value })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            {airdropNames &&
              airdropNames.map((airdrop) => (
                <option value={airdrop.airdrop_id}> {airdrop.name} </option>
              ))}
          </select>
        </div>

        <div className="mb-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Quest Level
          </label>
          <input
            type="number"
            value={conditions.questLevel}
            onChange={(e) =>
              setConditions({ ...conditions, questLevel: e.target.value })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

      </div>

      <button
        type="button"
        onClick={editAirdrop}
        disabled={btn}
        className="bg-blue-500 text-white p-2 rounded-lg"
      >
        {btnTxt}
      </button>
    </form>
  );
}

export default AirdropEdit;
