import React, { useState, useEffect } from "react";
import { MarketPlaceConnect } from "../connect/marketplace.connect";
import { useParams } from "react-router-dom";

function MarketPlace() {
  const { projectId } = useParams();
  const marketplace = new MarketPlaceConnect();
  const [numberOfQuest, setNumberOfQuest] = useState(0);
  const [mines, setMines] = useState(null);
  const [search, setSearch] = useState("");

  const onEdit = (mine_id) => {
    window.location = `/item-edit/${mine_id}`;
  };

  const getMines = async () => {
    try {
      const { data: response } = await marketplace.getAllItems();
      if (response) {
        setMines(response);
      }
    } catch (error) {
      console.log({ getMines: error });
      alert(marketplace.handleTheError(error));
    }
  };

  const createMineLevel = async (data) => {
    try {
      const { data: response } = await marketplace.createMineLevel({
        ...data,
        numberOfQuest,
      });
      if (response) {
        getMines();
        alert("Quest Added");
      }
    } catch (error) {
      alert(marketplace.handleTheError(error));
    }
  };

  useEffect(() => {
    getMines();
  }, []);

  return (
    <div className="max-w-sm mx-auto mt-10">
      <p className="text-white font-bold text-center text-[40px] mb-2">
        MarketPlace
      </p>

      <div className="grid grid-cols-2 gap-4 mb-8">
        <a
          className="px-8 py-2 text-white text-center rounded-lg bg-blue-800"
          href="/item"
        >
          Create Item
        </a>

        <a
          className="px-8 py-2 text-white text-center rounded-lg bg-blue-800"
          href="/dailyCombo"
        >
          View Daily Combo
        </a>

        <a
          className="px-8 py-2 text-white text-center rounded-lg bg-blue-800"
          href="/create-dailyCombo"
        >
          Create Daily Combo
        </a>
      </div>

      <div className="text-center p-2 m-2">
        <input
          className="p-2 m-2 bg-gray-800 text-white"
          value={search}
          placeholder="filter by name and id"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      {mines &&
        mines
        .filter((mine) => {
          const searchLowerCase = search.toLowerCase();
          const mineTitle = (mine?.title || "").toLowerCase();
          const mineId = (mine?.mine_id || "").toLowerCase();
          return (
            mineTitle.includes(searchLowerCase) || 
            mineId.includes(searchLowerCase)
          );
        })
          .map((mine) => (
            <div
              key={mine.mine_id}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 p-4 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              <div className="flex items-center mb-2">
                <div>
                  <p className="text-lg font-semibold">{mine.title}</p>
                  <pre className="text-sm font-sans text-white">
                    {mine?.mine_id}
                  </pre>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {mine.typeAction}
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Disabled: {mine.disabled ? "YES" : "NO"}
                  </p>
                </div>
              </div>
              <img src={mine.image} alt={mine.title} className="mb-2" />
              <p className="mb-2">
                Category:{" "}
                <span className="uppercase font-bold">{mine.category}</span>
              </p>
              <p className="mb-2">Description: {mine.description}</p>
              {mine.isReferralBased && (
                <div className="mb-2">
                  <p>Referral Count: {mine.isReferralBased.referralCount}</p>
                  {mine.isReferralBased.increment !== null && (
                    <p>Increment: {mine.isReferralBased.increment}</p>
                  )}
                </div>
              )}
              {mine.isLevelBased && (
                <div className="mb-2">
                  <p>Mine ID: {mine.isLevelBased.mine_id}</p>
                  <p>Level Up: {mine.isLevelBased.levelUp}</p>
                </div>
              )}
              {mine.mineLevel && mine.mineLevel.length > 0 && (
                <div className="mb-2">
                  <p className="font-bold">Mine Levels:</p>
                  {mine.mineLevel.slice(0, 10).map((level, index) => (
                    <div
                      className="rounded-md bg-slate-800 p-2 m-2"
                      key={index}
                    >
                      <p>Level: {level.level}</p>
                      <p>
                        Per Hour Gain:{" "}
                        {(level?.perHourGain ?? 0).toLocaleString()}
                      </p>
                      <p>Cost: {(level?.cost ?? 0).toLocaleString()}</p>
                    </div>
                  ))}
                </div>
              )}

              <div className="flex justify-between">
                <button
                  onClick={() => onEdit(mine.mine_id)}
                  className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                >
                  Edit
                </button>

                <>
                  <input
                    className="border rounded-lg bg-transparent text-center m-2 p-2"
                    type="number"
                    value={numberOfQuest}
                    onChange={(e) => setNumberOfQuest(e.target.value)}
                  />
                  <button
                    onClick={() => createMineLevel(mine)}
                    className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                  >
                    Add Level
                  </button>
                </>
              </div>
            </div>
          ))}
    </div>
  );
}

export default MarketPlace;
