import { AxiosConnect } from "./axios.connect";

export class LevelConnect {

    async createLevel(data) {
     const { data: response } = await AxiosConnect.post(`/level`, data);
     if(response && response.data){
        return response;
     } else {
        alert("Network Error, could not create Level,");
     }
    }

    async allLevels(projectId) {
        const { data: response } = await AxiosConnect.get(`/level/all/${projectId}`);
        if(response && response.data){
           return response;
        } else {
           alert("Network Error, could not create Level,");
        }
    }

    async getLevel(level_id) {
      const { data: response } = await AxiosConnect.get(`/level/${level_id}`);
      if(response && response.data){
         return response;
      } else {
         alert("Network Error, could not create Level,");
      }
  }

    async editLevel(data) {
        const { data: response } = await AxiosConnect.post(`/level/update`, data);
        if(response && response.data){
           return response;
        } else {
           alert("Network Error, could not create Level");
        }
    }

    handleTheError(AxiosError) {
        let errorMessage = AxiosError.response?.data?.message || AxiosError.message || 'Request failed';
        console.error('Error message:', errorMessage);
        return errorMessage;
    }
}